import { useState } from "react";
import Router, { useRouter } from "next/router";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { login } from "utils/auth/account";
import styles from "./login.module.scss";

const Login = () => {
  const query = useRouter().query;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const cred = await login(username, password);
      if (cred) {
        if (!cred.user.emailVerified) {
          setError("Please check your emails and verify your account.");
        } else {
          Router.push("/");
        }
      } else {
        setError(
          "Login failed. Please check your login details and try again."
        );
      }
    } catch (err) {
      console.log(err);
      setError("Login failed. Please check your login details and try again.");
    }
  };

  return (
    <div className={styles.login}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>Fundlytics - Login</title>
      </Head>
      <div className="card mb-3 rounded-0">
        <div className="card-body">
          <header className="text-center m-5">
            <Link href="/" className="btn btn-default btn-block" passHref>
              <Image
                width={100}
                height={100}
                src="/a-logo1.svg"
                alt="Fundlytics"
              />
            </Link>
          </header>
          <form onSubmit={handleSubmit}>
            {query && query.email && (
              <div className="alert alert-success" role="alert">
                Please check your emails and follow the instructions.
              </div>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="form-group">
              <label className="d-none" htmlFor="name">
                Email
              </label>
              <input
                type="email"
                className="form-control rounded-0"
                id="email"
                placeholder="Email address"
                onChange={(e) => setUsername(e.target.value)}
                required
                autoFocus
              />
            </div>
            <div className="form-group mb-4">
              <label className="d-none" htmlFor="name">
                Password
              </label>
              <input
                type="password"
                className="form-control rounded-0"
                id="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-lg btn-block rounded-0"
            >
              Log In
            </button>
          </form>
        </div>
      </div>

      <div className="card rounded-0">
        <div className="card-body">
          <Link
            type="button"
            className="btn btn-default btn-block"
            href="/register"
          >
            Create an account
          </Link>
          <hr />
          <Link
            type="button"
            className="btn btn-default btn-block"
            href="/forgot"
          >
            Forgot your password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
